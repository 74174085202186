@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
 /* @apply bg-base-300;  */
 @apply bg-gradient-to-b from-ctp-mantle to-ctp-crust;
  }
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    /* background: rgba(255, 255, 255, 0.1); */
    background: rgba(203, 166, 247, 0.15);
    animation: animate 24s linear infinite;
}




.background li:nth-child(0) {
    left: 12%;
    width: 85px;
    height: 85px;
    bottom: -85px;
    animation-delay: 1s;
    background: var(--zeon-bonus-color-lighter) !important;
}
.background li:nth-child(1) {
    left: 49%;
    width: 34px;
    height: 34px;
    bottom: -34px;
    animation-delay: 1s;
}
.background li:nth-child(2) {
    left: 61%;
    width: 30px;
    height: 30px;
    bottom: -30px;
    animation-delay: 9s;
}
.background li:nth-child(3) {
    left: 54%;
    width: 96px;
    height: 96px;
    bottom: -96px;
    animation-delay: 8s;
}
.background li:nth-child(4) {
    left: 3%;
    width: 57px;
    height: 57px;
    bottom: -57px;
    animation-delay: 12s;
}
.background li:nth-child(5) {
    left: 8%;
    width: 45px;
    height: 45px;
    bottom: -45px;
    animation-delay: 23s;
}
.background li:nth-child(6) {
    left: 3%;
    width: 82px;
    height: 82px;
    bottom: -82px;
    animation-delay: 5s;
}
.background li:nth-child(7) {
    left: 63%;
    width: 87px;
    height: 87px;
    bottom: -87px;
    animation-delay: 10s;
}
.background li:nth-child(8) {
    left: 86%;
    width: 30px;
    height: 30px;
    bottom: -30px;
    animation-delay: 14s;
}
.background li:nth-child(9) {
    left: 23%;
    width: 56px;
    height: 56px;
    bottom: -56px;
    animation-delay: 17s;
}
.background li:nth-child(10) {
    left: 16%;
    width: 97px;
    height: 97px;
    bottom: -97px;
    animation-delay: 31s;
}
.background li:nth-child(11) {
    left: 43%;
    width: 82px;
    height: 82px;
    bottom: -82px;
    animation-delay: 21s;
}
.background li:nth-child(12) {
    left: 74%;
    width: 31px;
    height: 31px;
    bottom: -31px;
    animation-delay: 35s;
}
.background li:nth-child(13) {
    left: 19%;
    width: 97px;
    height: 97px;
    bottom: -97px;
    animation-delay: 57s;
}
.background li:nth-child(14) {
    left: 48%;
    width: 21px;
    height: 21px;
    bottom: -21px;
    animation-delay: 65s;
}
.background li:nth-child(15) {
    left: 34%;
    width: 69px;
    height: 69px;
    bottom: -69px;
    animation-delay: 18s;
}
.background li:nth-child(16) {
    left: 74%;
    width: 31px;
    height: 31px;
    bottom: -31px;
    animation-delay: 68s;
}
.background li:nth-child(17) {
    left: 13%;
    width: 41px;
    height: 41px;
    bottom: -41px;
    animation-delay: 81s;
}
.background li:nth-child(18) {
    left: 30%;
    width: 64px;
    height: 64px;
    bottom: -64px;
    animation-delay: 27s;
}
.background li:nth-child(19) {
    left: 56%;
    width: 59px;
    height: 59px;
    bottom: -59px;
    animation-delay: 79s;
}
.background li:nth-child(20) {
    left: 0%;
    width: 53px;
    height: 53px;
    bottom: -53px;
    animation-delay: 55s;
}
.background li:nth-child(21) {
    left: 30%;
    width: 82px;
    height: 82px;
    bottom: -82px;
    animation-delay: 1s;
}
.background li:nth-child(22) {
    left: 25%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 15s;
}
.background li:nth-child(23) {
    left: 11%;
    width: 34px;
    height: 34px;
    bottom: -34px;
    animation-delay: 88s;
}
.background li:nth-child(24) {
    left: 88%;
    width: 88px;
    height: 88px;
    bottom: -88px;
    animation-delay: 113s;
}
.background li:nth-child(25) {
    left: 42%;
    width: 61px;
    height: 61px;
    bottom: -61px;
    animation-delay: 24s;
}
.background li:nth-child(26) {
    left: 48%;
    width: 98px;
    height: 98px;
    bottom: -98px;
    animation-delay: 14s;
}
.background li:nth-child(27) {
    left: 81%;
    width: 20px;
    height: 20px;
    bottom: -20px;
    animation-delay: 4s;
}
.background li:nth-child(28) {
    left: 36%;
    width: 68px;
    height: 68px;
    bottom: -68px;
    animation-delay: 31s;
}
.background li:nth-child(29) {
    left: 80%;
    width: 81px;
    height: 81px;
    bottom: -81px;
    animation-delay: 72s;
}
.background li:nth-child(30) {
    left: 86%;
    width: 78px;
    height: 78px;
    bottom: -78px;
    animation-delay: 114s;
}
.background li:nth-child(31) {
    left: 31%;
    width: 68px;
    height: 68px;
    bottom: -68px;
    animation-delay: 141s;
}
.background li:nth-child(32) {
    left: 24%;
    width: 15px;
    height: 15px;
    bottom: -15px;
    animation-delay: 129s;
}
.background li:nth-child(33) {
    left: 60%;
    width: 98px;
    height: 98px;
    bottom: -98px;
    animation-delay: 101s;
}
.background li:nth-child(34) {
    left: 71%;
    width: 19px;
    height: 19px;
    bottom: -19px;
    animation-delay: 78s;
}
.background li:nth-child(35) {
    left: 31%;
    width: 32px;
    height: 32px;
    bottom: -32px;
    animation-delay: 23s;
}
.background li:nth-child(36) {
    left: 7%;
    width: 70px;
    height: 70px;
    bottom: -70px;
    animation-delay: 169s;
}
.background li:nth-child(37) {
    left: 3%;
    width: 26px;
    height: 26px;
    bottom: -26px;
    animation-delay: 98s;
}
.background li:nth-child(38) {
    left: 4%;
    width: 50px;
    height: 50px;
    bottom: -50px;
    animation-delay: 124s;
}
.background li:nth-child(39) {
    left: 34%;
    width: 51px;
    height: 51px;
    bottom: -51px;
    animation-delay: 30s;
}
.background li:nth-child(40) {
    left: 61%;
    width: 59px;
    height: 59px;
    bottom: -59px;
    animation-delay: 49s;
}
.background li:nth-child(41) {
    left: 78%;
    width: 45px;
    height: 45px;
    bottom: -45px;
    animation-delay: 184s;
}
.background li:nth-child(42) {
    left: 25%;
    width: 58px;
    height: 58px;
    bottom: -58px;
    animation-delay: 152s;
}
.background li:nth-child(43) {
    left: 20%;
    width: 76px;
    height: 76px;
    bottom: -76px;
    animation-delay: 157s;
}
.background li:nth-child(44) {
    left: 44%;
    width: 59px;
    height: 59px;
    bottom: -59px;
    animation-delay: 5s;
}
.background li:nth-child(45) {
    left: 36%;
    width: 88px;
    height: 88px;
    bottom: -88px;
    animation-delay: 217s;
}
.background li:nth-child(46) {
    left: 78%;
    width: 43px;
    height: 43px;
    bottom: -43px;
    animation-delay: 185s;
}
.background li:nth-child(47) {
    left: 59%;
    width: 58px;
    height: 58px;
    bottom: -58px;
    animation-delay: 91s;
}
.background li:nth-child(48) {
    left: 87%;
    width: 73px;
    height: 73px;
    bottom: -73px;
    animation-delay: 226s;
}
.background li:nth-child(49) {
    left: 43%;
    width: 79px;
    height: 79px;
    bottom: -79px;
    animation-delay: 84s;
}
