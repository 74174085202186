@keyframes float {
	 0% {
		/* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
		 transform: translatey(0px);
	}
	 50% {
		/* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
		 transform: translatey(-20px);
	}
	 100% {
		 /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
		 transform: translatey(0px);
	}
}
 .avatar {
	 width: 150px;
	 height: 150px;
	 box-sizing: border-box;  /* Not needed */
   /*   
	 border: 5px white solid;
	 border-radius: 50%;
	 */ 
background: transparent;
   overflow: hidden;
	/* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
	 transform: translatey(0px);
	 animation: float 6s ease-in-out infinite;
}
 .avatar img {
	 width: 100%;
	 height: auto;
}
 
