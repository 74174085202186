:root {
  --zeon-bonus-color:crgb(255, 255, 51); ;
  --zeon-bonus-color-ligter: rgba(255, 255, 51, 0.2);; 
}
.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  padding: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motioni: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 50s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* @apply mocha; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 3vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}
@keyframes spin-text {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes zeon-popout-color {
  /* yellow, blue, red, orange */
  0% {
    background: #FFFF33 ;
  }
  25% {
    background: #3333FF ;
  }
  50% {
    background: #FF3131 ;
  }
  100% {
    background: #FF5F1F ;
  }body {
  overflow: hidden; /* Hide scrollbars */
}
}

/* for the zeon user script */
.zeon-icon-popout img {
  /* animation: App; */
  animation: App-logo-spin infinite .4s linear !important;
  /* animation: z; */
}
.zeon-icon-popout {
  animation: zeon-popout-color infinite 10s linear !important;
  /* background; */
  opacity: 0.5;
}

.test-box {
  @apply from-ctp-mauve to-ctp-crust outline-ctp-pink;
}
.zeon-word {
  @apply duration-500 transition;

} 
.zeon-word:hover { 
/* background: var(--zeon-bonus-color); */
/* @apply text-yellow-500; */
color: #f9e2af;
/* @apply from-ctp-pink to-ctp-mauve; */
}
.text-spin {
  animation: spin-text  infinite 40s linear !important;
}


body {
  overflow-x: hidden; /* Hide scrollbars */
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html, body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  color: #cdd6f4;
}
